import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import Logos from "../../components/logos";
import OSSection from "../../components/other-services-section";
import OASection from "../../components/our-approach-section";

const WebDevelopmentPageDubai = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			img1: wpMediaItem(title: { eq: "Web-Design-New" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			gatsbyImg: wpMediaItem(title: { eq: "WEB-DEVELOPMENT-GATSBYJS" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg1: wpMediaItem(title: { eq: "shopify" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			logoImg3: wpMediaItem(title: { eq: "Gatsby" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg4: wpMediaItem(title: { eq: "Firebase" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg5: wpMediaItem(title: { eq: "Semrush" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			hiveImg: wpMediaItem(title: { eq: "Hivehr-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sarahImg: wpMediaItem(title: { eq: "sarah-bennett-fullpage-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ellaImg: wpMediaItem(title: { eq: "Ellaroberta-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			facialImg: wpMediaItem(title: { eq: "Facialrehab-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ninjaImg: wpMediaItem(title: { eq: "Ninjaarena-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			javaImg: wpMediaItem(title: { eq: "javascript-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			gatsbyIconImg: wpMediaItem(title: { eq: "E-commerce-websites-icon-3" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "Web Development Dubai" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Web Development",
				item: {
					url: `${siteUrl}/dubai/web-development`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai/web-development`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<Hero
						dubai
						button1="primary-link-dark"
						button1Text="Get in touch"
						button1Link="/dubai/contact-us"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`JAMSTACK WEB \nDEVELOPMENT \n<span class="text-secondary">DUBAI</span>`}
						description="Building Jamstack websites that are quick, scalable and secure"
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#jamstack"
								>
									The JAMstack
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#what-we-use"
								>
									What we use
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center">
							<Col className=" text-lg-center">
								<h2 className="mb-5 display-5 text-primary">
									Bespoke web development solutions for Dubai businesses
								</h2>
								<p>
									Do you need a website that goes beyond a brochure website?
									Perhaps you require a complex backend system or the
									integration of various applications. Our team of expert
									developers are here to ensure your website meets all your
									business needs and requirements.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="jamstack" className="py-5 bg-white py-lg-7">
					<Container>
						<Row className=" align-items-center justify-content-between gx-7">
							<Col lg={7} className="">
								<h2 className="mb-2 text-primary">What is Jamstack?</h2>
								<p>
									Jamstack is an architectural approach that separates the
									front-end from your data and business logic. It is a way of
									future-proofing your website with added security, faster load
									times and a great user experience. The main business benefit
									of building in the Jamstack way is that you are able to scale
									your website as you grow.
								</p>
								<h4 className="mt-4 mb-2 text-primary">
									Content Delivery Network
								</h4>
								<p>
									With the Jamstack approach the front-end of your website is
									built into static pages and images which is highly optimised
									and compressed. This will then get published directly to a CDN
									reducing hosting costs, complexity of managing the critical
									servers and significantly reducing any security risks.
								</p>
								<h4 className="mt-4 mb-2 text-primary">Integration</h4>
								<p>
									The significant benefit of Jamstack sites is that you are able
									to use third party services for things such as authentication,
									payments, content and data in a more secure, flexible and
									manageable way. Every Jamstack site is API driven and has the
									ability to use these services at build-time and run-time from
									the browser.
								</p>
							</Col>

							<Col lg={4} className="position-relative d-none d-lg-block">
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 99px #00000026",
										width: "15rem",
										height: "15rem",
										overflow: "hidden",
									}}
									className="bg-white ms-auto d-flex align-items-center"
								>
									<GatsbyImage
										className="w-100 h-100"
										image={data.javaImg.gatsbyImage}
										alt={data.javaImg?.altText}
									/>
								</div>
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 99px #00000026",
										width: "15rem",
										height: "15rem",
										top: "-4rem",
										overflow: "hidden",
									}}
									className="px-4 bg-black me-auto d-flex align-items-center justify-content-center position-relative"
								>
									<p className="text-center text-white display-2">{`{ api }`}</p>
								</div>
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 99px #00000026",
										width: "15rem",
										height: "15rem",
										top: "-8rem",
										overflow: "hidden",
										backgroundColor: "#663399",
									}}
									className=" ms-auto position-relative"
								>
									<GatsbyImage
										className="w-100 h-100"
										image={data.gatsbyIconImg.gatsbyImage}
										alt={data.gatsbyIconImg.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section id="jamstack" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className=" align-items-center justify-content-between g-7">
							<Col lg={4} className="position-relative d-none d-lg-block">
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 99px #00000026",
										width: "15rem",
										height: "15rem",
										overflow: "hidden",
									}}
									className="bg-white ms-auto d-flex align-items-center"
								>
									<GatsbyImage
										className="w-100 h-100"
										image={
											data.javaImg.gatsbyImage
										}
										alt={data.javaImg?.altText}
									/>
								</div>
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 99px #00000026",
										width: "15rem",
										height: "15rem",
										top: "-4rem",
										overflow: "hidden",
									}}
									className="px-4 bg-black me-auto d-flex align-items-center justify-content-center position-relative"
								>
									<p className="text-center text-white display-2">{`{ api }`}</p>
								</div>
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 99px #00000026",
										width: "15rem",
										height: "15rem",
										top: "-8rem",
										overflow: "hidden",
										backgroundColor: "#663399",
									}}
									className=" ms-auto position-relative"
								>
									<GatsbyImage
										className="w-100 h-100"
										image={
											data.gatsbyIconImg.gatsbyImage
										}
										alt={data.gatsbyIconImg.altText}
									/>
								</div>
							</Col>
							<Col lg={7} className="">
								<h2 className="mb-4 text-primary">What is JAMStack?</h2>
								<p>
									JAMStack is an architecture approach that decouples the
									front-end from your data and business-logic. This protects
									your site, improves security, boosts performance and makes
									scaling your website easier as your business grows, so it
									won't cost you the earth. 
								</p>
								<Button
									className="px-4 py-2 mt-4 cta-btn w-100 w-md-auto fs-5 white-link-yellow"
									variant="primary"
									as={Link}
									to="/dubai/contact-us"
								>
									Get in touch
								</Button>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
					<Container>
						<Row>
							<Col lg={6}>
								<h2 className="mb-2 text-white text-uppercase fs-1">
									Static websites offer many{" "}
									<span className="text-light-blue">
										advantages to your Dubai business
									</span>
									, such as:
								</h2>
							</Col>
						</Row>
						<div style={{ height: "100px" }}></div>

						<Row className="g-6">
							<Col lg={4}>
								<div className="position-relative h-100">
									<p
										className="text-light-blue position-absolute translate-middle"
										style={{
											fontSize: "330px",
											top: "20%",
											left: "75%",
											opacity: "10%",
										}}
									>
										1
									</p>
									<p className="text-white position-relative fs-2 ssp-bold">
										Reduce the cost of hosting
									</p>
									<p className="text-white position-relative">
										Every Jamstack website is published directly to a CDN such
										as Netlify, Firebase and Fastly. Meaning it splits the
										website traffic across multiple servers around the world,
										delivering the website content closer to the user and
										caching it so it doesnt need to deliver the content lots of
										times.
									</p>
								</div>
							</Col>
							<Col lg={4}>
								<div className="position-relative h-100">
									<p
										className="text-light-blue position-absolute translate-middle"
										style={{
											fontSize: "330px",
											top: "20%",
											left: "75%",
											opacity: "10%",
										}}
									>
										2
									</p>
									<p className="text-white position-relative fs-2 ssp-bold">
										SEO - friendly
									</p>
									<p className="text-white position-relative">
										Your site will provide an optimal user experience and
										efficient performance, which is something that Google
										prioritises. Static sites load quickly, without any unneeded
										code, which can result in higher search rankings and better
										visibility for your business.
									</p>
								</div>
							</Col>
							<Col lg={4}>
								<div className="position-relative h-100">
									<p
										className="text-light-blue position-absolute translate-middle"
										style={{
											fontSize: "330px",
											top: "20%",
											left: "75%",
											opacity: "10%",
										}}
									>
										3
									</p>
									<p className="text-white position-relative fs-2 ssp-bold">
										Enhanced security
									</p>
									<p className="text-white position-relative">
										By separating the front-end from the back-end and compiling
										the website at build-time, you can enhance the security of
										your website by reducing potential points of failure for
										hacks and security flaws in your infrastructure.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
					<Container>
						<Row>
							<Col lg={6}>
								<h2 className="mb-4 text-white ">
									Benefits of Static Websites
								</h2>
								<p className="text-white">
									Static websites have many benefits for your business
									including:
								</p>
								<ul>
									<li className="text-white">Lower hosting costs</li>
									<li className="text-white">
										Less loading time (which search engines love)
									</li>
									<li className="text-white">Improved security</li>
								</ul>
							</Col>
							<Col lg={6} className="position-relative d-none d-lg-block">
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 99px #00000026",
										width: "25rem",
										height: "17rem",
										overflow: "hidden",
									}}
									className=" ms-auto"
								>
									{" "}
									<GatsbyImage
										className="w-100 "
										image={
											data.ninjaImg.gatsbyImage
										}
										alt={data.ninjaImg?.altText}
										objectPosition="top center"
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section> */}

				<section id="what-we-use" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center">
							<Col lg={6} className="">
								<h2 className="mb-5 text-primary fs-1">
									Why we use
									<br /> GatsbyJS
								</h2>
								<p>
									GatsbyJS is designed for fast development of content-rich
									websites that can be easily updated and integrated with
									third-party services like authentication and e-commerce.
								</p>
								<p>
									RJM Digital has built over 50 GatsbyJS websites for customers
									and became a formal agency partner in 2022, maintaining a
									strong partnership with the GatsbyJS team.
								</p>
								<Button
									className="px-4 py-2 mt-4 cta-btn w-100 w-md-auto fs-5 white-link-yellow"
									variant="primary"
									as={Link}
									to="/dubai/contact-us"
								>
									Get in touch
								</Button>
							</Col>
							<Col className="d-none d-lg-block" lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.gatsbyImg.gatsbyImage}
										alt={data.gatsbyImg?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 bg-light-grey position-relative py-lg-7">
					<div className="position-relative bg-med-grey">
						<Container className="pb-10 pt-7">
							<Row className="align-items-center ">
								<Col lg={6} className="">
									<h2 className="mb-4 display-5">Who we work with</h2>
									<p>
										RJM Digital enjoys working with growing and ambitious Dubai
										companies that are looking to use technology to develop
										their digital marketing to give them a competitive edge.
									</p>
								</Col>
							</Row>
						</Container>
					</div>
					<div style={{ top: "-5rem" }} className="position-relative">
						<Container>
							<Row className="g-6">
								<Col lg={4}>
									<div
										className=""
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											className="w-100 "
											image={data.hiveImg.gatsbyImage}
											alt={data.hiveImg?.altText}
											objectPosition="top center"
										/>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className=""
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											className="w-100 "
											image={data.sarahImg.gatsbyImage}
											alt={data.sarahImg?.altText}
											objectPosition="top center"
										/>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className=""
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											className="w-100 "
											image={data.ellaImg.gatsbyImage}
											alt={data.ellaImg?.altText}
											objectPosition="top center"
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</section>
				<div id="approach">
					<OASection
						dubai
						imgHeight="60rem"
						image={data.facialImg.gatsbyImage}
						imageAlt={data.facialImg?.altText}
						launch="Once the RJM Digital team has thoroughly tested everything on your website is functioning perfectly, we can proceed to launch it into the digital world."
						build="We will begin to construct your static website using the Jamstack architecture, ensuring quick load times and including all your specifications. You can monitor your website's progress on our test server, and we'll request your approval of the final design before launching it."
						design="Our developers will carefully build the architecture of your site, incorporating all your specific requirements. During this stage, you can make as many adjustments as needed until you're completely happy."
						brief="Our expert team will work with you to understand the functionalities of your website. The outcome will be a website that reflects your brand, represents your business and plays a crucial role in your marketing strategy."
					/>
				</div>
				<section className="py-5 py-lg-7 bg-dark-grey">
					<Container>
						<Row className="justify-content-center">
							<Col xs={6} lg={true}>
								<GatsbyImage
									style={{ width: "100%" }}
									image={data.logoImg1.gatsbyImage}
									alt={data.logoImg1?.altText}
								/>
							</Col>
							<Col xs={6} className="d-flex align-items-center" lg={true}>
								<StaticImage
									style={{ width: "100%" }}
									src="../../images/Wordpress-Final.png"
									placeholder="blurred"
									quality={60}
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									style={{ width: "100%" }}
									image={data.logoImg3.gatsbyImage}
									alt={data.logoImg3?.altText}
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									style={{ width: "100%" }}
									image={data.logoImg4.gatsbyImage}
									alt={data.logoImg4?.altText}
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									style={{ width: "100%" }}
									image={data.logoImg5.gatsbyImage}
									alt={data.logoImg5?.altText}
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pt-5 bg-light-grey pb-7 py-lg-8">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7 g-6">
							<Col lg={6}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #0000001D ",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={data.img1.gatsbyImage}
										alt={data.img1.altText}
									/>
								</div>
							</Col>
							<Col lg={6} className="">
								<h2 className="mb-5 text-primary">Pricing</h2>
								<p>
									Our web development provides you with a custom built website
									that meets the bespoke needs of your business. Contact us for
									a free discovery meeting, where we can propose a solution and
									provide you with a custom quote.
								</p>
								<Button
									className="px-4 py-2 mt-4 cta-btn w-100 w-md-auto fs-5 white-link-yellow"
									variant="primary"
									as={Link}
									to="/dubai/contact-us"
								>
									CONTACT US FOR A QUOTE
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<Logos />
				<section className="pt-5 pt-lg-7 pb-md-5 bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="mb-3 display-5 text-primary">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your project.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<div id="faq">
					<Faq page="Web Development" />
				</div>
				<OSSection
					dubai
					link3="/branding"
					text3="BRANDING"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/>
			</Layout>
		</>
	);
};

export default WebDevelopmentPageDubai;
